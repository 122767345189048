import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business } from 'models/Business';

export interface InitialState {
  inheritanceTypeFilter: ('Division' | 'Business' | 'BusinessWithDivisionOverride')[];
  customerSearch?: string;
  cachedBusinessList?: Business[];
  cachedAddressQuantity: { [key: string]: number };
}

export const initialState: InitialState = {
  inheritanceTypeFilter: ['Business', 'Division', 'BusinessWithDivisionOverride'],
  customerSearch: undefined,
  cachedBusinessList: undefined,
  cachedAddressQuantity: {}
};

export const businessListSlice = createSlice({
  name: 'businessList',
  initialState,
  reducers: {
    setInheritanceTypeFilter: (state, { payload }: PayloadAction<InitialState['inheritanceTypeFilter']>) => {
      state.inheritanceTypeFilter = payload;
    },
    setCustomerSearch: (state, { payload }: PayloadAction<InitialState['customerSearch']>) => {
      state.customerSearch = payload;
    },
    setCachedBusinessList: (state, { payload }: PayloadAction<InitialState['cachedBusinessList']>) => {
      state.cachedBusinessList = payload;
    },
    addBusinessAddressQuantity: (state, { payload }: PayloadAction<{ key: string; value: number }>) => {
      const { key, value } = payload;
      state.cachedAddressQuantity[key] = value;
    },
    clearCachedAddressQuantities: (state) => {
      state.cachedAddressQuantity = {};
    }
  }
});

export const { setInheritanceTypeFilter, setCustomerSearch, setCachedBusinessList, addBusinessAddressQuantity, clearCachedAddressQuantities } = businessListSlice.actions;
