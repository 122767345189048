import { Form, Select } from 'antd';
import { FormLabel } from 'components/UI/FormItems/FormLabel';
import { useFormikContext } from 'formik';
import { AddressPayload } from 'models/Address';
import React, { useState } from 'react';

const { Option } = Select;

interface Props {
  fieldName: string;
}

const AddressTypeDropdown: React.FC<Props> = ({ fieldName }) => {
  const { values, initialValues, setFieldValue, getFieldMeta } = useFormikContext<AddressPayload>();
  const { value, touched, error } = getFieldMeta<string>('addressType');
  const [inputValue, _setInputValue] = useState<string[]>(values.addressType || []);

  const handleStatusChange = (value: string[]): void => {
    setFieldValue('addressType', value);
  };

  return (
    <Form.Item style={{ margin: 0 }} label={<FormLabel label="Address Type" />}>
      <Select
        allowClear
        value={values.addressType}
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a Type"
        optionFilterProp="children"
        mode="multiple"
        onChange={handleStatusChange}
        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}>
        <Option value="Delivery">Delivery</Option>
        <Option value="Install">Install</Option>
        <Option value="Warehouse">Warehouse</Option>
      </Select>
    </Form.Item>
  );
};

export default AddressTypeDropdown;
