import { Col, List, Row, Space, message } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { RefetchButton } from 'components/atoms/RefetchButton';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLazyGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCachedBusinessList } from 'redux/slices/businessListSlice';
import { ReduxState, useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const { abbyCadabbyParams } = useSelector((state: ReduxState) => state);
  const { isTriggerLoading } = useSelector((state: ReduxState) => state.app);
  const { cachedBusinessList } = useSelector((state: ReduxState) => state.businessList);
  const [getBussinessLazy, { isFetching, isLoading }] = useLazyGetBusinessNameConatinsQuery();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const isRecycleBin = pathname.includes('recycle');

  const { customerSearch } = useAppSelector((state) => state.businessList);

  const businessData = cachedBusinessList;
  /* ******************** Render ******************** */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBussinessLazy(abbyCadabbyParams).unwrap();
        dispatch(setCachedBusinessList(response.data));
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    };
    if (!cachedBusinessList) fetchData();
  }, [cachedBusinessList]);

  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businessData) {
      for (const business of businessData) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]] as any;
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businessData, customerSearch]);

  return (
    <Space direction="vertical">
      <Row gutter={[16, 16]}>
        <Col>
          <AntPageTitle text={isRecycleBin ? 'Select a Business (Recycle Bin)' : 'Select a Business'} />
        </Col>
        <Col>
          <RefetchButton />
        </Col>
      </Row>
      <List
        loading={{
          indicator: <BlockLoader message={isTriggerLoading ? 'Refreshing bussiness list' : 'Loading bussiness list'} direction="loader loader--slideUp" />,
          size: 'large',
          spinning: isLoading || isFetching || isTriggerLoading
        }}
        dataSource={filteredData}
        renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
        rowKey={(item: any): string => item.id}
      />
    </Space>
  );
};
