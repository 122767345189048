import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Dropdown, Form, Menu, message, Modal, Row, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { StepControls } from 'components/atoms/StepControls';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { FormikProvider, useFormik } from 'formik';
import { AddressPayload, addressSchema } from 'models/Address';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDeleteAddressMutation, useGetAddressQuery, useUpdateAddressMutation } from 'redux/services/chuckieSue/addressesApi';
import { ReduxState } from 'redux/store';

export const EditAddressPage: React.FC = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const { id: addressId } = useParams();
  const [updateAddress, { isLoading: isAddressUpdating }] = useUpdateAddressMutation();
  const [deleteAddress, { isLoading: isAddressDeleting }] = useDeleteAddressMutation();
  const { company } = useSelector((state: ReduxState) => state.app);
  const { isAddressOveridden } = useSelector((state: ReduxState) => state.form);
  const [isClose, setIsClose] = useState(false);

  const { data: addressData, isLoading } = useGetAddressQuery(addressId ? { bussinessId: company as string, addressId } : skipToken);

  function trimObjectValues<T>(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    const trimmedObj = {} as T;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'string') {
          (trimmedObj as any)[key] = value.trim() as any;
        } else {
          (trimmedObj as any)[key] = value;
        }
      }
    }

    return trimmedObj;
  }

  /* ******************** API Hooks ******************** */
  const formik = useFormik<AddressPayload>({
    enableReinitialize: true,
    validationSchema: addressSchema,
    initialValues: {
      addressType: addressData?.addressType ?? [],
      name: addressData?.name ?? '',
      city: addressData?.city ?? '',
      code: addressData?.code ?? '',
      contactEmail: addressData?.contact?.email ?? '',
      contactName: addressData?.contact?.name ?? '',
      contactPhone: addressData?.contact?.phone ?? '',
      alpha2Code: addressData?.country?.alpha2Code ?? '',
      postalCode: addressData?.postalCode ?? '',
      street1: addressData?.street1 ?? '',
      street2: addressData?.street2 ?? '',
      localCode: addressData?.subdivision?.localCode ?? '',
      latitude: addressData?.latitude ?? null,
      longitude: addressData?.longitude ?? null,
      businessId: addressData?.business.id ?? '',
      locationId: addressData?.locationId ?? '',
      country: {
        alpha2Code: addressData?.country?.alpha2Code ?? '',
        alpha3Code: addressData?.country?.alpha3Code ?? '',
        fullName: addressData?.country?.fullName ?? '',
        shortName: addressData?.country?.shortName ?? ''
      },

      subdivision: {
        category: addressData?.subdivision?.categoryName ?? '',
        name: addressData?.subdivision?.name ?? '',
        localCode: addressData?.subdivision?.localCode ?? ''
      },
      suppressLatitudeLongitudeLookup: addressData?.suppressLatitudeLongitudeLookup ?? undefined,
      suppressErpAddressLinking: addressData?.suppressErpAddressLinking ?? undefined,
      warehouseType: addressData?.warehouseType ?? '',
      region: addressData?.region ?? ''
    },
    onSubmit: async (values) => {
      if (!company || !addressId) {
        message.error('An error occured, the team has been notified.');
        return;
      }
      try {
        await updateAddress({ bussinessId: company, addressId, payload: trimObjectValues(values), params: { skipAddressValidation: isAddressOveridden } }).unwrap();
        message.success(`Address was successfully updated!`);
        if (isClose) {
          return navigate(`/business/${company}/search`);
        }
        setIsClose(false);
      } catch (error) {
        setIsClose(false);
        const splitErrors = (error as { data: { detailedMessage: string } })?.data?.detailedMessage?.split(';');
        console.log(error);
        if (splitErrors) {
          Modal.error({
            width: 550,
            title: 'Error',
            content: (
              <>
                <Row style={{ marginTop: 16 }}>
                  <Typography.Text style={{ fontSize: 16 }}>{splitErrors[0]}</Typography.Text>
                </Row>
                <Row style={{ marginTop: 16 }}>
                  <Typography.Text style={{ fontSize: 16 }}>{splitErrors[1]}</Typography.Text>
                </Row>
              </>
            )
          });
        } else {
          message.error((error as { data: { errorMessage: string } }).data.errorMessage, 5);
        }
      }
    }
  });
  /* ******************** Functions ******************** */

  const handleClose = async (): Promise<void> => {
    await formik.submitForm();
    return navigate(`/business/${company}/search`);
  };

  const handleSave = async (close?: boolean): Promise<void> => {
    if (close) setIsClose(true);
    await formik.submitForm();
  };

  const handleDelete = async (): Promise<void> => {
    if (!company || !addressId) {
      message.error('Business or Address not found');
      return;
    }
    try {
      await deleteAddress({ bussinessId: company, addressId, params: { wouldYouLikeToPlayAGame: false } });
      message.success(`Address successfully deleted!`);
      navigate(`/business/${company}/search`);
    } catch (err) {
      console.error(err);

      message.error((err as { data: { detailMessage: string } }).data.detailMessage);
    }
  };

  /* ******************** Variables / Functions ******************** */
  const validBusinessStep = Boolean(formik.values.businessId);
  const validDeliveryAddressStep = Boolean(formik.values.code && formik.values.street1 && formik.values.city && formik.values.postalCode);

  const isValidPost = validBusinessStep && validDeliveryAddressStep;

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" disabled={isAddressUpdating || isAddressDeleting} onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );
  if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;
  return (
    <Spin spinning={isAddressUpdating || isAddressDeleting} indicator={<LoaderWithMessage loadingMessage={isAddressUpdating ? 'Updating address' : 'Deleting address'} />}>
      <Stack flexDirection="column">
        <FormikProvider value={formik}>
          <Col style={{ marginBottom: 12 }}>
            <Stack justifyContent="space-between" alignItems="top">
              <AntPageTitle text={`Editing Address ${addressData?.code}`} />
              <Stack>
                <Button style={{ borderRadius: 5 }} onClick={() => navigate(`/business/${addressId}/search`)} disabled={isAddressUpdating} icon={<ArrowLeftOutlined />}>
                  Back to Search
                </Button>
                <Button style={{ borderRadius: 5 }} onClick={handleDelete} disabled={isAddressUpdating} loading={isAddressDeleting} danger>
                  Delete
                </Button>
                <Dropdown.Button
                  style={{ borderRadius: 5 }}
                  icon={<SaveOutlined />}
                  overlay={menuJSX}
                  onClick={(): Promise<void> => handleSave(true)}
                  loading={formik.isSubmitting}
                  type="primary"
                  disabled={!isValidPost || isAddressUpdating}>
                  Save and Exit
                </Dropdown.Button>
              </Stack>
            </Stack>
          </Col>
          <StepControls />
          <Form layout="vertical">
            <Outlet />
          </Form>
        </FormikProvider>
      </Stack>
    </Spin>
  );
};
