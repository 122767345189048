import { Select } from 'antd';
import { useFormikContext } from 'formik';
import { AddressPayload } from 'models/Address';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { ReduxState } from 'redux/store';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

export const GoogleAutoComplete: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const { setValues, values } = useFormikContext<AddressPayload>();

  const { selectedBusiness } = useSelector((state: ReduxState) => state.selectedBusiness);
  const { isAddressOveridden } = useSelector((state: ReduxState) => state.form);
  const { data: worldsData, isLoading } = useGetWorldsQuery(undefined, { skip: !selectedBusiness?.dataAreaId });

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 500
  });

  const handleInput = (value: string): void => {
    // Update the keyword of the input element
    setValue(value);
    setSearchValue(value);
  };

  const handleSelect = async (value: string, option: { label: string; value: string; placeId: string }): Promise<void> => {
    const { placeId } = option;

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue('', false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    const { address_components }: { address_components: { long_name: string; short_name: string; types: string[] }[] } = await getDetails({
      placeId,
      fields: ['address_components']
    });

    const streetNumber = address_components.find((comp) => comp.types.includes('street_number'))?.long_name ?? undefined;
    const route = address_components.find((comp) => comp.types.includes('route'))?.long_name ?? undefined;
    const city = address_components.find((comp) => comp.types.includes('locality'))?.long_name ?? undefined;
    const state = address_components.find((comp) => comp.types.includes('administrative_area_level_1')) ?? undefined;
    const zipCode = address_components.find((comp) => comp.types.includes('postal_code'))?.long_name ?? undefined;
    const country = address_components.find((comp) => comp.types.includes('country')) ?? undefined;
    const subpremise = address_components.find((comp) => comp.types.includes('subpremise'))?.long_name ?? undefined;

    // street number can come back undefined so we have to parse it from the internal select value based on the route/street in front of it
    const streetFromValue = (route && `${value.split(route)[0].trim()} ${route}`) ?? '';

    setValues((prevState) => ({
      ...prevState,
      alpha2Code: country?.short_name ?? '',
      street1: !streetNumber ? streetFromValue : streetNumber && route ? `${streetNumber} ${route}` : '' ?? '',
      street2: subpremise ?? '',
      localCode: state?.short_name ?? '',
      city: city ?? '',
      postalCode: zipCode ?? '',
      subdivision: {
        categoryName: 'state',
        localCode: state?.short_name ?? '',
        name: state?.long_name ?? ''
      },
      country: {
        alpha2Code: country?.short_name ?? '',
        alpha3Code: '',
        fullName: country?.long_name ?? '',
        shortName: country?.long_name ?? ''
      }
    }));
  };

  return (
    <>
      <Select
        loading={isLoading}
        onSelect={handleSelect}
        options={data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
            description
          } = suggestion;

          return { value: description, label: description, placeId: place_id };
        })}
        onSearch={handleInput}
        disabled={!ready}
        placeholder="Address Lookup"
        showSearch
        filterOption={false}
        searchValue={searchValue}
        value={searchValue}
        notFoundContent={null}
      />
    </>
  );
};
