import { Col, List, Row, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import NoResults from 'components/atoms/noResults/noResults';
import { LoadingMore } from 'components/common/LoadMore';
import { AddressCard } from 'components/molecules/AddressCard';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { incrementOffset } from 'redux/services/chuckieSue/addressesParams';
import { ReduxState } from 'redux/store';

export const AddressList = (): JSX.Element => {
  const { company } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();
  const { chuckieSueAddressesParams } = useSelector((state: ReduxState) => state);
  const { data, isLoading, isFetching } = useGetAddressesQuery({ bussinessId: company as string, params: { ...chuckieSueAddressesParams, orderByField: 'code' } });
  const [loadingMore, setLoadingMore] = useState(false);

  const hasMore = Boolean(data && data.totalCount > data.data.length);

  const handleLoadMore = (): void => {
    dispatch(incrementOffset());
    setLoadingMore(true);
  };

  /* ******************** Render ******************** */
  if (company === null) {
    return (
      <Row justify="center" align="middle" style={{ marginTop: '100px' }}>
        <Col>
          <Typography.Title level={2} style={{ fontWeight: 300, color: 'rgba(0,0,0,.8)' }}>
            Please Select A Customer...
          </Typography.Title>
        </Col>
      </Row>
    );
  }

  if (!data?.data.length && !isFetching) {
    return <NoResults errorMessage="No Results Found" />;
  }

  if ((isLoading || isFetching) && chuckieSueAddressesParams.offset === 0) {
    return <BlockLoader direction="loader loader--slideUp" />;
  }

  return (
    <InfiniteScroll style={{ overflow: 'unset' }} loader={<LoadingMore />} hasMore={hasMore} next={handleLoadMore} dataLength={data?.data.length || 0}>
      <List
        grid={{ gutter: 16, column: 5 }}
        loading={{
          indicator: <BlockLoader direction="loader loader--slideUp" />,
          size: 'large',
          spinning: isLoading
        }}
        dataSource={data?.data}
        renderItem={(item): JSX.Element => <AddressCard address={item} />}
      />
    </InfiniteScroll>
  );
};
