import { Card, Col, Divider, List, Row, Space, Tooltip, Typography } from 'antd';
import { colors, toRgb } from 'common/styles/colors';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { setAddressSelected } from 'redux/slices/formSlice';
import { ReduxState } from 'redux/store';
import { ReactComponent as DeliveryIcon } from '../../common/assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../common/assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../common/assets/warehouse.svg';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  card: {
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    height: '100%',
    minHeight: 220,
    fontSize: 14
  },
  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 35,
    color: 'rgb(89, 93, 110)',
    marginBottom: 20,
    borderRadius: 45
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)'
  },
  deliveryIconStyle: {
    fontSize: '36px',
    color: toRgb(colors.royalBlueLight)
  },
  installIconStyle: {
    fontSize: '36px',
    color: toRgb(colors.orangeWeb)
  },
  warehouseIconStyle: {
    fontSize: '36px',
    color: toRgb(colors.illuminatingEmerald)
  }
};

type Props = {
  address: Address;
};

export const AddressCard: FC<Props> = ({ address }) => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { company } = useSelector((state: ReduxState) => state.app);

  /* ******************** Functions ******************** */

  const handleCardClick = () => {
    dispatch(setAddressSelected(address));
    navigate(`/business/${company}/edit/${address.id}`);
  };

  /* ******************** Render ******************** */
  return (
    <List.Item>
      <Card hoverable style={styles.card} onClick={handleCardClick}>
        <Row justify="space-between" style={{ height: 70 }}>
          <Col span={16} style={{ textAlign: 'left' }}>
            <Space direction="vertical" size={1}>
              <Tooltip title="Location Code" placement="top">
                <Typography.Title level={4} ellipsis={{ rows: 1 }} style={{ whiteSpace: 'normal', marginBottom: 0 }}>
                  {address?.code}
                </Typography.Title>
              </Tooltip>
              <Tooltip title="D365 Address RecId" placement="right">
                {address.locationId ? address.locationId : null}
              </Tooltip>
              <Tooltip title="Warehouse Type" placement="right">
                {address ? address?.warehouseType : null}
              </Tooltip>
            </Space>
          </Col>
          <Col span={8}>
            <Row gutter={[8, 0]} justify="center">
              {address?.addressType?.includes('Delivery') ? (
                <Tooltip title="Delivery" placement="bottom">
                  <Col style={{ paddingTop: 5 }}>
                    <DeliveryIcon width={30} height={35} />
                  </Col>
                </Tooltip>
              ) : null}
              {address?.addressType?.includes('Install') ? (
                <Tooltip title="Install" placement="bottom">
                  <Col>
                    <InstallIcon width={25} height={40} />
                  </Col>
                </Tooltip>
              ) : null}
              {address?.addressType?.includes('Warehouse') ? (
                <Tooltip title="Warehouse" placement="bottom">
                  <Col>
                    <WarehouseIcon width={25} height={40} />
                  </Col>
                </Tooltip>
              ) : null}
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginTop: 10 }} />
        <Row justify="space-between">
          <Col span={24}>
            <Tooltip title="Address" placement="right">
              <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)', textAlign: 'left', height: '100%' }}>
                {address?.street2 ? address?.street1 + ' ' + address?.street2 : address?.street1}
                <br />
                {address?.city + ', ' + address?.subdivision?.localCode + ' ' + address?.postalCode}
              </Typography.Paragraph>
            </Tooltip>
          </Col>
          <Col style={{ height: '100%', textAlign: 'left' }} span={24}>
            <Tooltip title="Country" placement="right">
              {address?.country?.shortName}
            </Tooltip>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
