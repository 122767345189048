import { Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { colors } from 'common/styles/colors';
import { toRgb } from 'common/stylesOLD/colors';
import { FancySelect } from 'components/atoms/Inputs';
import { Stack } from 'components/UI';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect } from 'react';
import { setAddressTypesInclude } from 'redux/services/chuckieSue/addressesParams';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ReactComponent as DeliveryIcon } from '../../../common/assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../../common/assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../../common/assets/warehouse.svg';

const styles: InlineStylesModel = {
  deliveryIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.royalBlueLight)
  },
  installIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.orangeWeb)
  },
  warehouseIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.illuminatingEmerald)
  }
};

export const TypeFilter: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { addressTypesInclude } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleTypeFilterChange = (type: LocationAddressTypes[]): void => {
    dispatch(setAddressTypesInclude(type));
  };

  const filterOptions: DefaultOptionType[] = [
    {
      label: (
        <Stack alignItems="center" height={20}>
          <DeliveryIcon width={20} />
          <Typography>Delivery</Typography>
        </Stack>
      ),
      value: 'Delivery'
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <InstallIcon width={15} />
          <Typography>Install</Typography>
        </Stack>
      ),
      value: 'Install'
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <WarehouseIcon width={15} />
          <Typography>Warehouse</Typography>
        </Stack>
      ),
      value: 'Warehouse'
    }
  ];

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setAddressTypesInclude([LocationAddressTypes.Delivery, LocationAddressTypes.Install, LocationAddressTypes.Warehouse]));
    };
  }, [dispatch]);

  /* ******************** Renderer ******************** */
  return (
    <Space align="center">
      <FancySelect
        placeholder="Type"
        // dropdownAlign={{ offset: [-40, 4] }}
        options={filterOptions}
        value={addressTypesInclude || [LocationAddressTypes.Delivery, LocationAddressTypes.Install, LocationAddressTypes.Warehouse]}
        onChange={handleTypeFilterChange}
        dropdownMatchSelectWidth={false}
        mode="multiple"
        defaultValue={addressTypesInclude}
      />
    </Space>
  );
};
