import { Space } from 'antd';
import { AddressList } from 'components/organisms/AddressList';
import { HomeControls } from 'components/organisms/HomeControls';

export const SearchPage = (): JSX.Element => {
  return (
    <Space size={16} direction="vertical">
      <HomeControls />
      <AddressList />
    </Space>
  );
};
