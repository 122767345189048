import { DoughnutChart } from 'components/atoms/Charts/DoughnutChart';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAddressesChartDataQuery, useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { ReduxState } from 'redux/store';

export const AddressTypeChart = (): JSX.Element => {
  const nav = useNavigate();
  const { company } = useSelector((state: ReduxState) => state.app);
  const { data: addressesData } = useGetAddressesQuery({
    bussinessId: company as string,
    params: {
      offset: 0,
      orderByField: undefined,
      orderByDirection: undefined,
      addressCodeContains: undefined,
      addressNameContains: undefined,
      addressTypesInclude: [LocationAddressTypes.Delivery, LocationAddressTypes.Install, LocationAddressTypes.Warehouse],
      street1Contains: undefined,
      cityContains: undefined,
      countyContains: undefined,
      countryAlpha2CodeEquals: undefined,
      subdivisionLocalCodeEquals: undefined,
      postalCodeContains: undefined,
      returnDeletedDataOnly: undefined,
      includeDeletedData: undefined
    }
  });

  const { data, isLoading, isFetching } = useGetAddressesChartDataQuery({
    bussinessId: company as string,
    params: {
      offset: 0,
      take: addressesData?.totalCount.toString()
    }
  });

  const handleLabelDataClick = (labelIndex: number): void => {
    const labelValue = data?.typeChart.labels[labelIndex];

    // if (labelValue === 'Leases') nav(`/search?assetType=LeasedEquipment`);
    // else {
    //   nav(`/search?assetType=${labelValue}`);
    // }
  };

  return (
    <DoughnutChart
      chartData={data?.typeChart || { labels: [], datasets: [] }}
      chartTitle={'Address Types'}
      isLoading={isLoading}
      chartCircumference={180}
      chartRotation={270}
      chartCutout={125}
      handleChartClick={handleLabelDataClick}
    />
  );
};
