import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'redux/services/chuckieSue/models/addresses';

interface InitialState {
  selectedAddress: Address | null;
  isAddressOveridden: boolean;
}

const initialState: InitialState = {
  selectedAddress: null,
  isAddressOveridden: false
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setAddressSelected: (state, { payload }: PayloadAction<InitialState['selectedAddress']>) => {
      state.selectedAddress = payload;
    },
    setIsAddressOverridden: (state, { payload }: PayloadAction<InitialState['isAddressOveridden']>) => {
      state.isAddressOveridden = payload;
    }
  }
});

export const { setAddressSelected, setIsAddressOverridden } = formSlice.actions;
