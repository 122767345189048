import { Space } from 'antd';
import { Country, State } from 'country-state-city';
import { AddressLabels } from 'models/Address';
import { DefaultOptionType } from 'rc-cascader';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setCountryAlpha2CodeEquals, setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const CountryStateInputs: React.FC = () => {
  const { subdivisionLocalCodeEquals, countryAlpha2CodeEquals } = useAppSelector((state) => state.chuckieSueAddressesParams);

  const countries = Country.getAllCountries();
  const states = countryAlpha2CodeEquals ? State.getStatesOfCountry(countryAlpha2CodeEquals) : [];

  const dispatch = useDispatch();

  const countryNames = countries?.map((country) => ({ label: country.name, value: country.isoCode }));

  const stateOptions: DefaultOptionType[] | undefined = states.map((state) => ({ label: state.name, value: state.isoCode }));

  const handleStateChange = (value: string): void => {
    dispatch(setSubdivisionLocalCodeEquals(value));
  };

  const handleCountryChange = (value: string): void => {
    dispatch(setCountryAlpha2CodeEquals(value));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return (
    <Space direction="horizontal">
      <FancySelect
        placeholder="Country"
        style={{ margin: 0 }}
        defaultActiveFirstOption={true}
        defaultOpen={false}
        value={countryAlpha2CodeEquals}
        onChange={handleCountryChange}
        showSearch
        options={countryNames ? countryNames : undefined}
      />
      <FancySelect
        showSearch
        placeholder={AddressLabels.subdivisionLocalCode}
        onChange={handleStateChange}
        onDeselect={(): any => dispatch(setSubdivisionLocalCodeEquals(undefined))}
        options={stateOptions}
        value={subdivisionLocalCodeEquals}
      />
    </Space>
  );
};
