import { Card, Col, Row, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { TextInput } from 'components/UI/FormItems';
import { useFormikContext } from 'formik';
import { AddressLabels } from 'models/Address';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect } from 'react';

const styles: InlineStylesModel = {
  headerContainer: {
    backgroundColor: toRgba(colors.romanSilver, 0.4)
  },
  headerTitle: {
    marginBottom: 0,
    fontWeight: 'normal'
  },
  container: {
    color: 'rgba(0,0,0, 0.85)',
    width: '100%'
  },
  logoSection: {
    display: 'flex',
    color: '#84859a',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingTop: 5
  }
};

export const ContactInfoPage: React.FC = () => {
  const { setFieldTouched } = useFormikContext();

  useEffect(() => {
    return () => {
      setFieldTouched('contactEmail', true);
      setFieldTouched('contactName', true);
      setFieldTouched('contactPhone', true);
    };
  }, []);

  return (
    <>
      <Card
        style={styles.container}
        title={
          <Row>
            <Typography.Title level={4} style={styles.headerTitle}>
              Contact Information
            </Typography.Title>
          </Row>
        }
        headStyle={styles.headerContainer}>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            <TextInput fieldName="contactName" label={AddressLabels.contactName} />
          </Col>
          <Col span={24}>
            <TextInput fieldName="contactEmail" label={AddressLabels.contactEmail} />
          </Col>
          <Col span={24}>
            <TextInput fieldName="contactPhone" label={AddressLabels.contactPhone} />
          </Col>
        </Row>
      </Card>
    </>
  );
};
