import { colors, toRgb, toRgba } from 'common/styles/colors';
import { BarChartModel } from 'models/Reports';
import { Address } from 'redux/services/chuckieSue/models/addresses';

interface ChartDataStager {
  name: string;
  totalCount: number;
}

const random_RGBA = (): string => {
  const o = Math.round,
    r = Math.random,
    s = 255;

  return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + '.6)';
};

export const monthConverter = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const monthIndexConverter = (monthName: string): number => {
  switch (monthName) {
    case 'January':
      return 0;

    case 'February':
      return 1;

    case 'March':
      return 2;

    case 'April':
      return 3;

    case 'May':
      return 4;

    case 'June':
      return 5;

    case 'July':
      return 6;

    case 'August':
      return 7;

    case 'September':
      return 8;

    case 'October':
      return 9;

    case 'November':
      return 10;

    case 'December':
      return 11;

    default:
      return -1;
  }
};

export const stateAbbreviations = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

// +++++++++++++++++++ HELPER FUNCTIONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const typeChartGenerator = (allAssets: Address[]): BarChartModel => {
  const addressStatusChartData = {
    labels: ['Delivery', 'Install', 'Warehouse'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [0, 0, 0], // Active, Cancelled, Expired
        // you can set indiviual colors for each bar
        backgroundColor: [toRgba(colors.robinEggBlue, 0.7), toRgba(colors.orangeWeb, 0.7), toRgba(colors.illuminatingEmerald, 0.7)],
        borderWidth: 3,
        borderColor: [toRgb(colors.robinEggBlue), toRgb(colors.orangeWeb), toRgb(colors.illuminatingEmerald)]
      }
    ]
  };

  allAssets?.map((eachAsset) => {
    // By Asset Status -----------------------------------------
    if (eachAsset.addressType.includes('Delivery')) {
      addressStatusChartData.datasets[0].data[0] += 1;
    }
    if (eachAsset.addressType.includes('Install')) {
      addressStatusChartData.datasets[0].data[1] += 1;
    }
    if (eachAsset.addressType.includes('Warehouse')) {
      addressStatusChartData.datasets[0].data[2] += 1;
    }
  });

  return addressStatusChartData;
};

export const stateChartGenerator = (allAssets: Address[]): BarChartModel => {
  const addressStateChartData = {
    labels: stateAbbreviations,
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [...Array(stateAbbreviations.length)].map(() => 0),
        // you can set indiviual colors for each bar
        backgroundColor: [...Array(stateAbbreviations.length)].map((each, index) => {
          return toRgba(colors.romanSilver, 0.5);
        }),
        borderWidth: 3
      }
    ]
  };

  allAssets?.map((eachAsset) => {
    // By Asset State -----------------------------------------
    const chartDataIndex = stateAbbreviations.indexOf(eachAsset?.subdivision?.localCode);
    if (chartDataIndex >= 0) addressStateChartData.datasets[0].data[chartDataIndex] += 1;
  });

  return addressStateChartData;
};

export const countryChartGenerator = (allAssets: Address[]): BarChartModel => {
  const countryList: string[] = [];
  const allCountries: ChartDataStager[] = [];

  allAssets?.map((eachAsset) => {
    // By country.alpha3Code -----------------------------------------
    if (countryList.includes(eachAsset.country.alpha3Code) === false && eachAsset.country.alpha3Code) {
      countryList.push(eachAsset.country.alpha3Code);
      allCountries.push({ name: eachAsset.country.alpha3Code, totalCount: 1 });
    } else {
      allCountries.map((each) => {
        if (each.name === eachAsset.country.alpha3Code) each.totalCount += 1;
      });
    }
  });

  return {
    labels: allCountries.map((each) => each.name),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object rgba(132,133,154,.6)
    datasets: [
      {
        label: 'Total Count',
        data: allCountries.map((each) => each.totalCount),
        // you can set indiviual colors for each bar
        backgroundColor: allCountries.map((each) => random_RGBA()),
        borderColor: allCountries.map((each) => 'rgba(0,0,0,.2)')
      }
    ]
  };
};

export const googleVerifiedChartGenerator = (allAssets: Address[]): BarChartModel => {
  const addressVerifiedChartData = {
    labels: ['Verified', 'Unverified'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [0, 0], // Active, Cancelled, Expired
        // you can set indiviual colors for each bar
        backgroundColor: [toRgba(colors.illuminatingEmerald, 0.7), toRgba(colors.redSalsa, 0.7)],
        borderWidth: 3,
        borderColor: [toRgb(colors.illuminatingEmerald), toRgb(colors.redSalsa)]
      }
    ]
  };

  allAssets?.map((eachAsset) => {
    // By Asset Status -----------------------------------------
    if (eachAsset.isVerifiedByGoogle) {
      addressVerifiedChartData.datasets[0].data[0] += 1;
    } else if (!eachAsset.isVerifiedByGoogle) {
      addressVerifiedChartData.datasets[0].data[1] += 1;
    }
  });

  return addressVerifiedChartData;
};
