import React, { useEffect } from 'react';
import { setStreet1Contains } from 'redux/services/chuckieSue/addressesParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const StreetFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setStreet1Contains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setStreet1Contains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Street" onChange={handleChange} />;
};
