import { Button, Col, Row, Space, Spin } from 'antd';
import { AddressCountriesChart } from 'components/organisms/AddressCountryChart';
import { StateChart } from 'components/organisms/AddressStateChart';
import { AddressTypeChart } from 'components/organisms/AddressTypeChart';
import { AddressVerifiedChart } from 'components/organisms/AddressVerifiedChart';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCompany, setCompanyName } from 'redux/slices/appSlice';

export const Dashboard = (): JSX.Element => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleSelectNewBusiness = () => {
    nav('/');
    dispatch(setCompany(undefined));
    dispatch(setCompanyName(undefined));
  };

  return (
    <Space size={16} direction="vertical">
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <AntPageTitle text="Dashboard" />
            </Col>
            <Col>
              <Button onClick={handleSelectNewBusiness} style={{ borderRadius: 5 }}>
                Change Business
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <AddressTypeChart />
        </Col>
        <Col span={8}>
          <AddressCountriesChart />
        </Col>
        <Col span={8}>
          <Spin tip="Google Location Verification Coming Soon..." indicator={<></>}>
            <AddressVerifiedChart />
          </Spin>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} style={{ height: '100%' }}>
          <StateChart />
        </Col>
      </Row>
    </Space>
  );
};
