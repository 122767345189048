import { Button, message, Row } from 'antd';
import { Users } from 'models/Users';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { chuckieSueAddressesApi, useDeleteAddressMutation, useGetAddressesQuery, useRecoverAddressMutation } from 'redux/services/chuckieSue/addressesApi';
import { setIsDeleteing } from 'redux/slices/appSlice';
import { removeAllAddress, selectAllAddress } from 'redux/slices/selectedAddressesSlice';
import { ReduxState } from 'redux/store';

interface Props {
  deletedUsers?: Users[] | undefined;
}

const RecoverActions = ({ deletedUsers }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { company } = useSelector((state: ReduxState) => state.app);
  const { chuckieSueAddressesParams } = useSelector((state: ReduxState) => state);
  const { selectedAddresses } = useSelector((state: ReduxState) => state.addressSlice);
  const { data: addresses, isLoading, isFetching } = useGetAddressesQuery({ bussinessId: company as string, params: { ...chuckieSueAddressesParams, returnDeletedDataOnly: true } });

  const [recoverAddress, { isLoading: isRecoveringAddress }] = useRecoverAddressMutation();
  const [deleteAddress, { isLoading: isDeletingAddress }] = useDeleteAddressMutation();

  const handleRestoreAddress = async (): Promise<void> => {
    if (selectedAddresses.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const address of selectedAddresses) {
      try {
        await recoverAddress({ bussinessId: company as string, addressId: address.id }).unwrap();
      } catch {
        message.error(`Address ${address.code} failed to be recovered!`);
      }
    }

    dispatch(removeAllAddress());
    message.success(`Addresses were successfully recovered!`);
    chuckieSueAddressesApi.util.invalidateTags(['Addresses']);
  };
  const handlePurgeAddress = async (): Promise<void> => {
    if (selectedAddresses.length < 1) {
      message.error('No entity selected!');

      return;
    }
    dispatch(setIsDeleteing(true));
    let errorArray: string[] = [];

    for (const address of selectedAddresses) {
      try {
        await deleteAddress({ bussinessId: company as string, addressId: address.id, params: { wouldYouLikeToPlayAGame: true } }).unwrap();
      } catch (err) {
        console.log(err);
        errorArray.push((err as { data: { errorMessage: string } }).data.errorMessage);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }

    dispatch(removeAllAddress());
    if (!errorArray.length) {
      message.success(`Addresses were successfully purged!`);
    }
    chuckieSueAddressesApi.util.invalidateTags(['Addresses']);
    dispatch(setIsDeleteing(false));
  };

  const handleSelectAll = (): void => {
    if (addresses) {
      dispatch(selectAllAddress(addresses.data));
    }
  };

  const handleClearAll = (): void => {
    dispatch(removeAllAddress());
  };

  return (
    <Row justify="end">
      <Row justify="space-between">
        <Button style={{ marginRight: 5 }} onClick={() => naviagte('/')}>
          Back
        </Button>
      </Row>
      <Row justify="space-between">
        <Button disabled={deletedUsers?.length === 0} style={{ marginRight: 5 }} type="primary" onClick={selectedAddresses.length ? handleClearAll : handleSelectAll}>
          {!selectedAddresses.length ? 'Select All' : 'Clear All'}
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreAddress}
          style={selectedAddresses.length ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={isRecoveringAddress}
          disabled={!selectedAddresses.length}>
          Restore Selected ({selectedAddresses.length})
        </Button>
        <Button danger onClick={handlePurgeAddress} loading={isRecoveringAddress} disabled={!selectedAddresses.length}>
          Purge Selected ({selectedAddresses.length})
        </Button>
      </Row>
    </Row>
  );
};

export default RecoverActions;
