import { Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FancySelect } from 'components/atoms/Inputs';
import React, { useEffect } from 'react';
import { setAddressTypesInclude, setWarehouseTypeEquals } from 'redux/services/chuckieSue/addressesParams';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const WarehouseTypeFilter: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { warehouseTypeEquals } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleTypeFilterChange = (type: string): void => {
    dispatch(setWarehouseTypeEquals(type));
  };

  const filterOptions: DefaultOptionType[] = ['DC', 'SSL', 'Regional SSL', 'Customer Premise', 'OEM/Vendor'].map((wh) => ({ label: wh, value: wh }));
  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setAddressTypesInclude([LocationAddressTypes.Delivery, LocationAddressTypes.Install, LocationAddressTypes.Warehouse]));
    };
  }, [dispatch]);

  /* ******************** Renderer ******************** */
  return (
    <Space align="center">
      <FancySelect placeholder="Warehouse Type" options={filterOptions} value={warehouseTypeEquals} onChange={handleTypeFilterChange} dropdownMatchSelectWidth={false} width={175} allowClear />
    </Space>
  );
};
