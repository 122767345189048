import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Space, Switch, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import AddressTypeDropdown from 'components/atoms/AddressTypeDropdown';
import { GoogleAutoComplete } from 'components/atoms/GoogleAutoComplete';
import { CountrySelectInput } from 'components/atoms/Inputs/CountrySelectInput';
import { StateSelectInput } from 'components/atoms/Inputs/StateSelectInput';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { FancyNumberInput } from 'components/UI/FormItems/FancyNumberInput';
import { FormLabel } from 'components/UI/FormItems/FormLabel';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { useField, useFormikContext } from 'formik';
import { Address, AddressLabels } from 'models/Address';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { setIsAddressOverridden } from 'redux/slices/formSlice';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  headerContainer: {
    backgroundColor: toRgba(colors.romanSilver, 0.4)
  },
  headerTitle: {
    marginBottom: 0,
    fontWeight: 'normal'
  },
  container: {
    color: 'rgba(0,0,0, 0.85)',
    width: '100%'
  }
};

interface FormModel {
  city: string;
  country: string;
  description: string;
  state: string;
  street: string;
  zipCode: string;
}

export const AddressInfoPage: React.FC = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { data } = useGetWorldsQuery();
  const [, , { setValue: setStateValue }] = useField('localCode');
  const { company } = useSelector((state: ReduxState) => state.app);
  const { isAddressOveridden } = useSelector((state: ReduxState) => state.form);
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);

  const [form] = Form.useForm<FormModel>();

  const dispatch = useDispatch();

  const { values, setValues, setFieldTouched, setFieldValue } = useFormikContext<Address>();

  const isWarehouse = Boolean(values.addressType.some((val) => val === 'Warehouse') && values.addressType.length === 1);

  useEffect(() => {
    if (!isWarehouse) setFieldValue('warehouseType', '');
  }, [isWarehouse]);

  useEffect(() => {
    return () => {
      setFieldTouched('code', true);
      setFieldTouched('street1', true);
      setFieldTouched('postalCode', true);
      setFieldTouched('city', true);
    };
  }, []);

  console.log('values', values);

  return (
    <Card
      style={{ marginBottom: 32 }}
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4} style={styles.headerTitle}>
              Address Information
            </Typography.Title>
          </Col>
          <Row>
            <Space>
              <Col>
                <Button
                  icon={<ArrowRightOutlined />}
                  onClick={(): void => (id ? nav(`/business/${company}/edit/${id}/contact-information`) : nav(`/business/${company}/create/contact-information`))}
                  style={{ borderRadius: 5 }}>
                  Continue to Contact Info
                </Button>
              </Col>
            </Space>
          </Row>
        </Row>
      }
      headStyle={styles.headerContainer}>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Form.Item style={{ margin: 0 }} label={<FormLabel title="Address Lookup" label="Address Lookup" />}>
            <GoogleAutoComplete />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[100, 20]}>
        <Col span={12}>
          <Space direction="vertical" size={25} style={{ width: '100%' }}>
            <TextInput disabled={!isAddressOveridden} fieldName="street1" placeholder={AddressLabels.street1} label={AddressLabels.street1} />
            <TextInput fieldName="street2" placeholder={AddressLabels.street2} label={AddressLabels.street2} />
            <TextInput disabled={!isAddressOveridden} fieldName="city" placeholder={'City'} label={'City'} />
            <StateSelectInput disabled={!isAddressOveridden} fieldName="localCode" onChange={(value: any): void => setStateValue(value.toString())} />
            <TextInput disabled={!isAddressOveridden} fieldName="postalCode" placeholder={'Postal Code'} label={'Postal Code'} />
            <CountrySelectInput disabled={!isAddressOveridden} currentValue={values.alpha2Code} />
            <TextInput fieldName="code" label={AddressLabels.code} placeholder={AddressLabels.code} />
            <Form.Item style={{ margin: 0 }} label={<FormLabel title="Override Address Verification" label="Override Address Verification" />}>
              <Switch checked={isAddressOveridden} onChange={() => dispatch(setIsAddressOverridden(!isAddressOveridden))} />
            </Form.Item>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={25} style={{ width: '100%' }}>
            <TextInput fieldName="name" placeholder={AddressLabels.name} label={AddressLabels.name} />
            <AddressTypeDropdown fieldName="addressType" />
            {isWarehouse && <SelectInput allowClear label="Warehouse Type" selectOptions={['DC', 'SSL', 'Regional SSL', 'Customer Premise', 'OEM/Vendor']} fieldName="warehouseType" />}
            <TextInput fieldName="region" label="Region" placeholder="Region" />
            {/* <TextInput fieldName="theatre" label="Theatre" placeholder="Street" /> */}
            <TextInput fieldName="locationId" placeholder={AddressLabels.locationId} label={AddressLabels.locationId} />
            <FancyNumberInput
              isDisabled={id ? true : false}
              isCurrency={false}
              placeHolder="Latitude"
              onInputChange={(e) => (e.floatValue ? setFieldValue('latitude', e.floatValue) : null)}
              value={String(values.latitude) || undefined}
            />
            <FancyNumberInput
              isDisabled={id ? true : false}
              isCurrency={false}
              placeHolder="Longitude"
              onInputChange={(e) => (e.floatValue ? setFieldValue('longitude', e.floatValue) : null)}
              value={String(values.longitude) || undefined}
            />
            <SwitchInput fieldName="suppressLatitudeLongitudeLookup" label="Suppress Latitude/Longitude Lookup" />
            <SwitchInput fieldName="suppressErpAddressLinking" label="Suppress Erp Address Linking" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
