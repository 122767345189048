import * as yup from 'yup';

export interface CountryPayload {
  alpha2Code: string;
  alpha3Code: string;
  fullName: string;
  shortName: string;
}

export interface Address {
  addressType: string[];
  city: string;
  code: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  alpha2Code: string;
  county: string;
  name: string;
  postalCode: string;
  street1: string;
  street2?: string | null;
  localCode: string;
  latitude?: number | null;
  longitude?: number | null;
  businessId: string;
  id?: string;
  locationId: string | null;
  suppressErpAddressLinking: boolean;
  suppressLatitudeLongitudeLookup: boolean;
  warehouseType: string;
  region: string;
}
export interface AddressPayload {
  addressType: string[];
  city: string;
  code: string;
  name: string;
  contactEmail?: string | null;
  contactName?: string;
  contactPhone?: string;
  alpha2Code: string;
  postalCode: string;
  street1: string;
  street2?: string;
  localCode?: string;
  latitude?: number | null;
  longitude?: number | null;
  businessId?: string;
  locationId?: string;
  country: CountryMetadata;
  subdivision: SubdivisionMetadata;
  suppressLatitudeLongitudeLookup?: boolean;
  suppressErpAddressLinking?: boolean;
  warehouseType?: string;
  region?: string;
}

export type CountryMetadata = {
  alpha2Code?: string;
  alpha3Code?: string;
  fullName?: string;
  shortName?: string;
};

export type SubdivisionMetadata = {
  category?: string;
  name?: string;
  localCode?: string;
};

export interface AddressValidationPayload {
  city: string;
  alpha2Code: string;
  postalCode: string;
  street1: string;
  street2?: string | null;
  localCode: string;
}

export enum AddressLabels {
  city = 'City',
  code = 'Location Code',
  country = 'Country',
  countryAlpha2Code = 'Country',
  id = '',
  latitude = 'Latitude',
  longitude = 'Longitude',
  name = 'Address Name',
  postalCode = 'Postal Code',
  street1 = 'Street',
  street2 = 'Street2',
  subdivision = 'State/Province',
  subdivisionLocalCode = 'State/Province',
  addressType = 'Address Type',
  contactName = 'Name',
  contactEmail = 'Email',
  contactPhone = 'Phone',
  referenceNumber = 'Reference Number',
  locationId = 'D365 Address Location Id'
}

export const addressSchema: yup.SchemaOf<AddressPayload> = yup.object({
  addressType: yup
    .array()
    .test('empty-types-check', 'State Cant Be Empty', (types) => !types || types.length > 0)
    .required(),
  city: yup.string().label(AddressLabels.city).required(),
  code: yup.string().label(AddressLabels.code).required().nullable(),
  contactEmail: yup.string().email().label(AddressLabels.contactEmail).nullable(),
  contactName: yup.string().label(AddressLabels.contactName),
  contactPhone: yup.string().label(AddressLabels.contactPhone),
  locationId: yup.string().label(AddressLabels.locationId),
  alpha2Code: yup
    .string()
    .label(AddressLabels.countryAlpha2Code)
    .test('empty-check', 'Country Cant Be Empty', (countryLocation) => !countryLocation || countryLocation !== '')
    .required(),
  postalCode: yup.string().label(AddressLabels.postalCode).required(),
  street1: yup.string().label(AddressLabels.street1).required(),
  street2: yup.string().label(AddressLabels.street2),
  localCode: yup.string(),
  businessId: yup.string().required(),
  latitude: yup.number().label(AddressLabels.latitude).nullable(),
  longitude: yup.number().label(AddressLabels.longitude).nullable(),
  name: yup.string().label(AddressLabels.name).required(),
  country: yup.object().shape({
    alpha2Code: yup.string().notRequired(),
    alpha3Code: yup.string().notRequired(),
    fullName: yup.string().notRequired(),
    shortName: yup.string().notRequired()
  }),
  subdivision: yup.object().shape({
    category: yup.string().notRequired(),
    name: yup.string().notRequired(),
    localCode: yup.string().notRequired()
  }),
  warehouseType: yup.string().notRequired(),
  region: yup.string().notRequired(),
  suppressErpAddressLinking: yup.boolean(),
  suppressLatitudeLongitudeLookup: yup.boolean()
});
