import { InlineStylesModel } from 'models/InlineStylesModel';
import { FC } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormLabel } from './FormLabel';

interface FancyNumberInputProps {
  value: string | undefined;
  placeHolder: string;
  onInputChange: (value: NumberFormatValues) => void;
  isCurrency: boolean;
  isDisabled: boolean;
}

const styles: InlineStylesModel = {
  borderAnimation: {
    // transform: 'translateX(-100%)',
    transition: 'all 0.3s ease'
  },
  input: {
    width: '100%',
    minWidth: 125
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    top: 5,
    left: 10,
    zIndex: 2,
    transition: 'all 0.3s ease'
  },
  labelFocused: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(bottom white 50%, lightblue 50%)',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 2,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  numberInput: {
    padding: 4,
    width: '100%',
    marginTop: 8
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const FancyNumberInput: FC<FancyNumberInputProps> = ({ value, placeHolder, onInputChange, isCurrency, isDisabled }: FancyNumberInputProps) => {
  return (
    <div style={styles.form}>
      <FormLabel label={placeHolder} />
      <NumberFormat
        disabled={isDisabled}
        className="ant-input-number"
        placeholder={placeHolder}
        thousandSeparator={true}
        prefix={isCurrency ? '$' : undefined}
        decimalScale={isCurrency ? 2 : undefined}
        fixedDecimalScale={isCurrency}
        style={styles.numberInput}
        onValueChange={onInputChange}
        value={value}
        allowLeadingZeros={true}
        allowNegative={true}
      />
    </div>
  );
};
