import { Form, Space, Switch, SwitchProps } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';
interface Props extends SwitchProps {
  fieldName: string;
  label: string;
  labelPosition?: 'top' | 'right';
  isInheritanceBusiness?: boolean;
}

export const SwitchInput: FC<Props> = ({ fieldName, label, labelPosition, isInheritanceBusiness, ...rest }) => {
  const { getFieldMeta, getFieldHelpers } = useFormikContext<any>();
  const { value, touched, error } = getFieldMeta<boolean>(fieldName);
  const { setValue } = getFieldHelpers(fieldName);

  return (
    <Space direction="vertical">
      <Form.Item
        hasFeedback={touched && !error}
        labelAlign="left"
        validateStatus={touched && error ? 'error' : 'success'}
        label={<FormLabel label={label} />}
        help={touched && error ? error : undefined}
        style={{ marginBottom: 0, color: 'rgba(0,0,0,0.85)' }}>
        <Switch checked={value || undefined} onChange={(e) => setValue(e)} {...rest} />
      </Form.Item>
    </Space>
  );
};
