import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'redux/services/chuckieSue/models/addresses';

interface InitialState {
  selectedAddresses: Address[];
}

const initialState: InitialState = {
  selectedAddresses: []
};

export const addressSlice = createSlice({
  name: 'addressSlice',
  initialState: initialState,
  reducers: {
    addAddress: (state, { payload }: PayloadAction<Address>) => {
      state.selectedAddresses = [...state.selectedAddresses, payload];
    },
    removeAddress: (state, { payload }: PayloadAction<string>) => {
      state.selectedAddresses = state.selectedAddresses.filter((selectedAddress) => selectedAddress.id !== payload);
    },
    selectAllAddress: (state, { payload }: PayloadAction<Address[]>) => {
      state.selectedAddresses = payload;
    },
    removeAllAddress: (state) => {
      state.selectedAddresses = [];
    }
  }
});

export const { addAddress, removeAddress, selectAllAddress, removeAllAddress } = addressSlice.actions;
