import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setIsTriggerLoading } from 'redux/slices/appSlice';
import { clearCachedAddressQuantities, setCachedBusinessList } from 'redux/slices/businessListSlice';
import { ReduxState } from 'redux/store';

export const RefetchButton = (): JSX.Element => {
  const { abbyCadabbyParams } = useSelector((state: ReduxState) => state);
  const dispatch = useDispatch();

  const lastRefetch = localStorage.getItem('currentDatePostOffice');

  const [getBussinessAsync, { isFetching, isLoading }] = useLazyGetBusinessNameConatinsQuery();

  const [refetchDate, setRefetchDate] = useState(lastRefetch);

  const handleClick = async () => {
    try {
      dispatch(setIsTriggerLoading(true));
      const response = await getBussinessAsync(abbyCadabbyParams).unwrap();
      dispatch(setIsTriggerLoading(false));
      dispatch(setCachedBusinessList(response.data));
      dispatch(clearCachedAddressQuantities());
      setRefetchDate(localStorage.getItem('currentDatePostOffice'));
      localStorage.setItem('currentDatePostOffice', JSON.stringify(new Date()));
      message.success('Customers successfully refreshed.');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      dispatch(setIsTriggerLoading(false));
    }
  };

  const formatDate = (): string => {
    const dateObj = refetchDate ? new Date(JSON.parse(refetchDate)) : 'Data has never been refreshed, Click here to refresh the list of customers';
    if (typeof dateObj === 'string') return dateObj;
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString();
    const formattedDate = `Data last cached on ${date} at ${time}. Click here to refresh the list of customers`;
    return formattedDate;
  };

  return (
    <Tooltip title={formatDate()}>
      <Button disabled={isLoading || isFetching} icon={<RedoOutlined />} onClick={handleClick}>
        Refresh Business List
      </Button>
    </Tooltip>
  );
};
