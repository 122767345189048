import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business } from 'models/Business';

interface InitialState {
  selectedBusiness: Business | undefined;
}

const initialState: InitialState = {
  selectedBusiness: undefined
};

export const selectedBusiness = createSlice({
  name: 'selectedBusiness',
  initialState,
  reducers: {
    setselectedBusiness: (state, { payload }: PayloadAction<Business | undefined>) => {
      state.selectedBusiness = payload;
    }
  }
});

export const { setselectedBusiness } = selectedBusiness.actions;
