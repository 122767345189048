import { AddressFormMenuKeys } from 'common/enums/MenuKeys';
import { About } from 'pages/About';
import { AddressInfoPage } from 'pages/Create/AddressInfoPage';
import { ContactInfoPage } from 'pages/Create/ContactInfoPage';
import { CreateAddressPage } from 'pages/CreateAddressPage';
import { Dashboard } from 'pages/Dashboard';
import { EditAddressPage } from 'pages/EditAddressPage';
import { EditProfilePage } from 'pages/EditProfile';
import { RecyclingBinPage } from 'pages/RecyclingBin';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/business/:businessId" element={<Dashboard />} />
      <Route path="/business/:businessId/search" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/recycle-bin" element={<RecyclingBinPage />} />
      <Route path="/business/:businessId/create" element={<CreateAddressPage />}>
        <Route index element={<AddressInfoPage />} />
        <Route path={AddressFormMenuKeys.AddressInformation} element={<AddressInfoPage />} />
        <Route path={AddressFormMenuKeys.ContactInformation} element={<ContactInfoPage />} />
      </Route>
      <Route path="/business/:businessId/edit/:id" element={<EditAddressPage />}>
        <Route index element={<AddressInfoPage />} />
        <Route path="address-information" element={<AddressInfoPage />} />
        <Route path="contact-information" element={<ContactInfoPage />} />
      </Route>
      <Route path="/users/recycling-bin" element={<RecyclingBinPage />} />
      <Route path="/profile/" element={<EditProfilePage />} />
    </Routes>
  );
};
