import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { resetParams } from 'redux/services/chuckieSue/addressesParams';
import { ReduxState } from 'redux/store';

export const RecyclingBinList = (): JSX.Element => {
  const { company } = useSelector((state: ReduxState) => state.app);
  const { chuckieSueAddressesParams } = useSelector((state: ReduxState) => state);
  const { isDeleting } = useSelector((state: ReduxState) => state.app);

  const {
    data: addressData,
    isLoading,
    isFetching
  } = useGetAddressesQuery({ bussinessId: company as string, params: { ...chuckieSueAddressesParams, returnDeletedDataOnly: true, includeDeletedData: true } });
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  /* ******************** Render ******************** */
  return (
    <List
      grid={{ gutter: 16, column: 2, xs: 1, sm: 1, md: 3, lg: 4, xl: 4, xxl: 4 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching || isDeleting
      }}
      style={{ margin: 0 }}
      dataSource={addressData?.data}
      renderItem={(item): JSX.Element => <RecyclingBinCard address={item} />}
    />
  );
};
