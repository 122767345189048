import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { CityFilter } from 'components/atoms/Filters/CityFilter';
import { LocationIdFilter } from 'components/atoms/Filters/ErpRecIdFilter';
import { LocationCodeFilter } from 'components/atoms/Filters/LocationCodeFilter';
import { StreetFilter } from 'components/atoms/Filters/StreetFilter';
import { TypeFilter } from 'components/atoms/Filters/TypeFilter';
import { WarehouseTypeFilter } from 'components/atoms/Filters/WarehouseTypeFilter';
import { ZipCodeFilter } from 'components/atoms/Filters/ZipCodeFilter';
import { CountryStateInputs } from 'components/atoms/Inputs/CountryStateInputs';
import RecoverActions from 'components/molecules/RecoverActions';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setCompany, setCompanyName } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  container: {
    flex: 1
  },
  controlsContainer: {
    backgroundColor: 'white',
    padding: 20,
    justifyContent: 'space-between'
  },
  searchRow: {
    padding: '20px 10px',
    backgroundColor: 'white'
  },
  addressHeadline: {
    fontSize: '17px',
    marginBottom: 10
  },
  divider: {
    margin: '9px 0px'
  }
};

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { businessId } = useParams();
  const { companyName } = useAppSelector((state) => state.app);
  const isRecycleBin = pathname.includes('recycle-bin');

  const handleSelectNewBusiness = () => {
    nav('/');
    dispatch(setCompany(undefined));
    dispatch(setCompanyName(undefined));
  };

  return (
    <>
      {!isRecycleBin ? (
        <Row style={styles.addressHeadline} align="middle" justify="space-between">
          <AntPageTitle text={pathname.includes('recover') ? 'Search Addresses - Recycle Bin' : 'Search Addresses'} />
          <Space>
            <Col>
              <Button onClick={handleSelectNewBusiness} style={{ borderRadius: 5 }}>
                Change Business
              </Button>
            </Col>
            <Col>
              <Button onClick={() => nav(`/recycle-bin/`)} icon={<DeleteOutlined />}>
                Recycle Bin
              </Button>
            </Col>
          </Space>
        </Row>
      ) : (
        <>
          <Row style={styles.addressHeadline} align="middle" justify="space-between">
            <Col style={{ paddingRight: 16 }}>
              <Row gutter={[8, 8]} justify="center">
                <Col>
                  <AntPageTitle text="Search Addresses - Recycle Bin" />
                </Col>
              </Row>
            </Col>
            <Col>
              <Stack justifyContent="start">
                <RecoverActions />
              </Stack>
            </Col>
          </Row>
          <Row gutter={[8, 15]} style={{ backgroundColor: 'white', padding: '10px 8px', margin: 0 }}>
            <Col>
              <LocationCodeFilter />
            </Col>
            <Col>
              <LocationIdFilter />
            </Col>
            <Col>
              <StreetFilter />
            </Col>
            <Col>
              <CityFilter />
            </Col>
            <Col>
              <ZipCodeFilter />
            </Col>
            <Col>
              <CountryStateInputs />
            </Col>
            <Col span={12}>
              <TypeFilter />
            </Col>
          </Row>
        </>
      )}
      {!isRecycleBin && (
        <Row gutter={[8, 15]} style={{ padding: '16px 8px', background: 'white', margin: 0, width: '100%' }}>
          <Col>
            <LocationCodeFilter />
          </Col>
          <Col>
            <LocationIdFilter />
          </Col>
          <Col>
            <StreetFilter />
          </Col>
          <Col>
            <CityFilter />
          </Col>
          <Col>
            <ZipCodeFilter />
          </Col>
          <Col>
            <CountryStateInputs />
          </Col>
          <Col>
            <WarehouseTypeFilter />
          </Col>
          <Col>
            <TypeFilter />
          </Col>
        </Row>
      )}
    </>
  );
};
