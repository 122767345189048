import { Space } from 'antd';
import { BusinessList } from 'components/organisms/BusinessList';
import { HomeControls } from 'components/organisms/HomeControls';
import { RecyclingBinList } from 'components/organisms/ReyclingBinList';
import { useAppSelector } from 'redux/store';

export const RecyclingBinPage = (): JSX.Element => {
  const { company } = useAppSelector((state) => state.app);

  return (
    <Space size={16} direction="vertical">
      {company ? (
        <>
          <HomeControls />
          <RecyclingBinList />
        </>
      ) : (
        <BusinessList />
      )}
    </Space>
  );
};
