import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, removeAddress } from 'redux/slices/selectedAddressesSlice';
import { ReduxState } from 'redux/store';
import { ReactComponent as DeliveryIcon } from '../../common/assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../common/assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../common/assets/warehouse.svg';

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  card: {
    borderRadius: 5,
    textAlign: 'center'
  },
  selectedCard: {
    // border: '2px solid #5867dd',
    borderRadius: 5,
    textAlign: 'center'
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '22px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: 'orange'
  },
  posted: {
    color: '#4E937A'
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  },

  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 60,
    color: 'rgb(89, 93, 110)',
    marginBottom: 20,
    width: 60,
    borderRadius: 45
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)'
  }
};

interface Props {
  address: any;
}

const RecyclingBinCard = ({ address }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { selectedAddresses } = useSelector((state: ReduxState) => state.addressSlice);

  const isSelected = selectedAddresses.some((add) => add.id === address.id);

  const handleToggleSelectAddress = (): void => {
    if (isSelected) {
      dispatch(removeAddress(address.id));
    } else {
      dispatch(addAddress(address));
    }
  };

  const readOnlyCardJSX = (
    <Card hoverable style={styles.card}>
      <Row justify="space-between">
        <Col span={16} style={{ textAlign: 'left' }}>
          <Tooltip title="Location Code" placement="right">
            <Typography.Title level={4} ellipsis={{ rows: 1 }} style={{ whiteSpace: 'normal', marginBottom: 0 }}>
              {address?.code}
            </Typography.Title>
          </Tooltip>
          <Tooltip title="D365 Address RecId" placement="right">
            {address.locationId ? address.locationId : null}
          </Tooltip>
        </Col>
        <Col span={8}>
          <Row gutter={[8, 0]} justify="center">
            {address?.addressType?.includes('Delivery') ? (
              <Tooltip title="Delivery" placement="bottom">
                <Col style={{ paddingTop: 5 }}>
                  <DeliveryIcon width={40} height={35} />
                </Col>
              </Tooltip>
            ) : null}
            {address?.addressType?.includes('Install') ? (
              <Tooltip title="Install" placement="bottom">
                <Col>
                  <InstallIcon width={35} height={40} />
                </Col>
              </Tooltip>
            ) : null}
            {address?.addressType?.includes('Warehouse') ? (
              <Tooltip title="Warehouse" placement="bottom">
                <Col>
                  <WarehouseIcon width={35} height={40} />
                </Col>
              </Tooltip>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Divider style={{ marginTop: 10 }} />
      <Row justify="space-between">
        <Col span={16}>
          <Row>
            <Col span={24}>
              <Tooltip title="Address" placement="right">
                <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)', textAlign: 'left', height: '100%' }}>
                  {address?.street2 ? address?.street1 + ' ' + address?.street2 : address?.street1}
                  <br />
                  {address?.city + ', ' + address?.subdivision?.localCode + ' ' + address?.postalCode}
                </Typography.Paragraph>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Button onClick={handleToggleSelectAddress} style={{ borderRadius: '50%' }} type={false ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
        </Col>
        <Col style={{ height: '100%', textAlign: 'left' }} span={20}>
          <Tooltip title="Country" placement="right">
            {address?.country?.shortName}
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      {readOnlyCardJSX}
    </List.Item>
  );
};

export default RecyclingBinCard;
