import { CheckOutlined, ExclamationOutlined, ProfileOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Menu, Row, Space } from 'antd';
import { colors, toRgb } from 'common/styles/colors';
import { useFormikContext } from 'formik';
import { AddressPayload } from 'models/Address';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReduxState } from 'redux/store';

export enum CreateRequestMenu {
  addressInformation = 'address-information',
  contactInformation = 'contact-information'
}

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    overflow: 'hidden'
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 40
  },
  navIcon: {
    paddingRight: 20,
    paddingLeft: 20,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent'
  }
};

export const StepControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { values, errors, touched, setTouched } = useFormikContext<AddressPayload>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id: addressID } = useParams();

  const [contactPageTouch, setContactPageTouch] = useState(false);
  const { company } = useSelector((state: ReduxState) => state.app);

  /* ******************** Variables / Functions ******************** */

  const validBusinessStep = Boolean(values.businessId);
  const validDeliveryAddressStep = Boolean(values.code && values.street1 && values.city && values.postalCode && values.localCode && values.addressType.length > 0);
  const validContactInfoStep = Boolean(values.contactName && values.contactEmail && values.contactPhone);

  const addressInfoHasErrors = Boolean(
    (errors.addressType && touched.addressType) ||
      (errors.street1 && touched.street1) ||
      (errors.localCode && touched.localCode) ||
      (errors.city && touched.city) ||
      (errors.code && touched.code) ||
      (errors.postalCode && touched.postalCode)
  );

  const currentLocation = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.addressInformation)) {
      return CreateRequestMenu.addressInformation;
    } else if (pathname.includes(CreateRequestMenu.contactInformation)) {
      return CreateRequestMenu.contactInformation;
    } else {
      return CreateRequestMenu.addressInformation;
    }
  }, [pathname]);

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.addressInformation) {
      navigate(`/business/${company}/create`);
    } else if (info.key === CreateRequestMenu.addressInformation) {
      navigate(`/business/${company}/create/address-information`);
    } else if (info.key === CreateRequestMenu.contactInformation) {
      navigate(`/business/${company}/create/contact-information`);
    }
  };
  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.addressInformation) {
      navigate(`/business/${company}/edit/${addressID}/address-information`);
    } else if (info.key === CreateRequestMenu.contactInformation) {
      navigate(`/business/${company}/edit/${addressID}/contact-information`);
    }
  };

  useEffect(() => {
    if (pathname.includes('contact-information')) {
      setContactPageTouch(true);
    }
  }, [pathname]);

  return (
    <Row justify="center" style={{ width: '100%', background: 'white', borderRadius: 5 }}>
      <Menu disabledOverflow={true} onClick={addressID ? handleNavigationEdit : handleNavigationCreate} mode="horizontal" style={{ justifyContent: 'center' }} selectedKeys={[currentLocation]}>
        <Menu.Item disabled={false} key={CreateRequestMenu.addressInformation}>
          <Space style={undefined}>
            <SearchOutlined />
            <div>Address Information</div>
            {addressInfoHasErrors && <ExclamationOutlined style={{ color: toRgb(colors.redSalsa) }} />}
            {validDeliveryAddressStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={false} key={CreateRequestMenu.contactInformation}>
          <Space style={undefined}>
            <ProfileOutlined />
            <div>Contact Information</div>
            {contactPageTouch && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
          </Space>
        </Menu.Item>
      </Menu>
    </Row>
  );
};
