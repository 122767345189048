import { Card, Typography } from 'antd';
import { Chart, defaults, registerables } from 'chart.js';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { BarChartModel } from 'models/Reports';
import { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

interface Props {
  chartData: BarChartModel;
  chartTitle: string;
  isLoading: boolean;
  handleChartClick: any;
}

const styles: InlineStylesModel = {
  cardBodyStyle: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 5,
    paddingBottom: 20
  }
};

Chart.register(...registerables);
defaults.font.family = 'Poppins';

export const BarChart = ({ chartData, chartTitle, isLoading, handleChartClick }: Props): JSX.Element => {
  const chartRef = useRef();
  const nav = useNavigate();

  const handleClick = (event: any) => {
    if (chartRef.current) {
      if (getElementAtEvent(chartRef.current, event).length > 0) {
        const labelDataIndex = getElementAtEvent(chartRef.current, event)[0].index;

        handleChartClick(labelDataIndex);
      }
    }
  };

  return (
    <Card title={<Typography.Title level={4}>{chartTitle}</Typography.Title>} loading={isLoading} bodyStyle={styles.cardBodyStyle}>
      <Bar
        style={{ height: 250 }}
        ref={chartRef}
        onClick={handleClick}
        typeof="bar"
        data={chartData}
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              align: 'start',
              display: false,
              text: chartTitle,
              font: {
                size: 28
              },
              color: 'black',
              padding: 10
            },
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              position: 'left',
              ticks: {
                color: 'black'
              }
            },
            x: {
              ticks: {
                color: 'black',
                font: {
                  size: 14
                }
              }
            }
          }
        }}
      />
    </Card>
  );
};
